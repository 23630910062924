<template>
  <div style="position: relative">
    <Breadcrumb :Breadcrumb="BreadcrumbCon" />
    <div class="status">
      <el-select v-model="searchType" placeholder="请选择查询类别">
        <el-option label="全部" value="0"></el-option>
        <el-option label="身份证号查询" value="1"></el-option>
        <el-option label="手机号查询" value="2"></el-option>
        <el-option label="日期区间查询" value="3"></el-option>
      </el-select>
      <el-input
        :disabled="true"
        v-if="this.searchType == ''"
        placeholder="请先选择类别"
      ></el-input>
      <div v-if="this.searchType !== ''" style="display: flex">
        <el-select
          v-model="valueArea"
          filterable
          clearable
          placeholder="请选择区号(可搜索关键字)"
          style="width: 240px"
          v-if="this.searchType !== '3' && internationType"
        >
          <el-option
            v-for="item in options"
            :key="item.name"
            :label="item.name + item.tel"
            :value="item.tel"
          >
          </el-option>
        </el-select>

        <div>
          <el-input
            v-model="number"
            placeholder="请输入要查询的身份证号"
            v-if="this.searchType == '1'"
          ></el-input>

          <el-input
            v-model="phone"
            placeholder="请输入要查询的手机号"
            v-else-if="this.searchType == '2'"
          ></el-input>

          <el-date-picker
            v-model="date"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            v-else-if="this.searchType == '3'"
            end-placeholder="结束日期"
            format="yyyy 年 MM 月 dd 日"
            value-format="yyyy-MM-dd"
            :clearable="false"
          >
          </el-date-picker>
          <el-input v-else placeholder="请输入要查询的内容"></el-input>
        </div>
      </div>

      <div>
        <el-button type="primary" @click="searchList">搜索</el-button>
        <el-button type="info" @click="reset">重置</el-button>
      </div>
    </div>

    <div class="table_con">
      <el-table :data="tableData" border style="width: 100%" height="100%">
        <el-table-column type="index" label="序号" width="80" align="center">
          <template slot-scope="scope">
            <span>{{ scope.$index + (currentPage - 1) * pageSize + 1 }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="phone" label="手机号" width="180">
          <template slot-scope="scope">
            {{ scope.row.phoneArea }} {{ scope.row.phone }}
          </template>
        </el-table-column>
        <el-table-column prop="realName" label="认证姓名" width="180">
        </el-table-column>
        <el-table-column prop="idCard" label="身份证号"> </el-table-column>
        <el-table-column prop="authTime" label="完成认证时间">
        </el-table-column>
        <el-table-column prop="remark" label="备注"> </el-table-column>
        <el-table-column fixed="right" label="操作" width="100">
          <template slot-scope="scope">
            <el-button @click="handleClick(scope.row)" type="text" size="small"
              >解除实名</el-button
            >
          </template>
        </el-table-column>
      </el-table>

      <Pagination
        v-show="pageLength >= 0"
        :total="pageLength"
        :limit.sync="pageSize"
        @pagination="handlePageChange"
        :page-sizes="pageSizes"
      />
    </div>
  </div>
</template>

<script>
import Breadcrumb from "@/components/BreadCrumb";
import Pagination from "@/components/Pagination";

import { reqUrl } from "@/api/apiUrl";
import { request } from "@/api/request";
import { showLoading, hideLoading } from "@/util/loading";
import { areaCodeData } from "@/util/areaCodeData";

export default {
  components: {
    Breadcrumb,
    Pagination,
  },
  inject: ["reload"],
  data() {
    return {
      number: "",
      phone: "",
      valueArea: "",
      options: [],
      date: "",
      all: "",
      tableData: [],
      searchType: "",
      BreadcrumbCon: [
        {
          name: "日常管理",
        },
        {
          name: "实名认证",
        },
        {
          name: "实名认证列表",
        },
      ], // 面包屑数据

      // 分页数据
      currentPage: 1, // 当前页
      pageLength: 0,
      pageSize: 10, //要传过去的数据 每页多少条数据
      pageSizes: [10, 20, 50, 100],

      internationType: false,
      role: "",
      phoneAreaROLE: "",
    };
  },

  mounted() {
    this.options = areaCodeData;

    this.role = localStorage.getItem("ROLE");
    var strNation = this.role.includes("ROLE_nation");
    if (strNation) {
      this.internationType = true;
    }
    this.phoneAreaROLE = localStorage.getItem("phoneArea");

    showLoading();
    const opt = {
      url: reqUrl.realNameList,
      method: "POST",
      params: JSON.stringify({
        endTime: "",
        idCard: "",
        nonce: "",
        pageNum: 0,
        pageSize: 0,
        phone: "",
        phoneArea: this.phoneAreaROLE,
        sign: "",
        startTime: "",
        token: "",
        type: "",
      }),
      resFunc: (res) => {
        console.log(res.data);
        if (res.data.code == 0) {
          this.tableData = res.data.data.list;
          this.pageLength = res.data.data.total;
          this.pageSize = res.data.data.pageSize;
          hideLoading();
        }
        if (res.data.code != 0) {
          this.$notify.error({
            title: "错误",
            message: res.data.message,
          });
          hideLoading();
        }
        hideLoading();
      },
      errFunc: (err) => {
        this.$notify.error({
          title: "错误",
          message: err,
        });
        hideLoading();
        console.log(err, "err1");
      },
    };
    request(opt);
  },

  methods: {
    searchList() {
      showLoading();
      const opt = {
        url: reqUrl.realNameList,
        method: "POST",
        params: JSON.stringify({
          endTime: this.date[1],
          idCard: this.number,
          nonce: "",
          pageNum: 0,
          pageSize: 0,
          phone: this.phone,
          phoneArea: this.valueArea || this.phoneAreaROLE,
          sign: "",
          startTime: this.date[0],
          token: "",
          type: this.searchType,
        }),
        resFunc: (res) => {
          console.log(res.data);
          if (res.data.code == 0) {
            this.tableData = res.data.data.list;
            this.pageLength = res.data.data.total;
            this.pageSize = res.data.data.pageSize;
            hideLoading();
          } else if (res.data.code != 0) {
            this.$notify.error({
              title: "错误",
              message: res.data.message,
            });
            hideLoading();
          }
          hideLoading();
        },
        errFunc: (err) => {
          this.$notify.error({
            title: "错误",
            message: err,
          });
          console.log(err, "err1");
          hideLoading();
        },
      };
      request(opt);
    },

    handleClick(row) {
      this.$confirm("是否解除绑定？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          const opt = {
            url: reqUrl.destroyRealName,
            method: "POST",
            params: JSON.stringify({
              auditor: localStorage.getItem("userName"),
              nonce: "",
              phone: "",
              sign: "",
              token: "",
              userId: row.userId,
            }),
            resFunc: (res) => {
              console.log(res.data);
              if (res.data.code == 0) {
                this.$message({
                  type: "success",
                  message: "解除成功!",
                });
                this.refresh();
              }
              if (res.data.code != 0) {
                this.$notify.error({
                  title: "错误",
                  message: res.data.message,
                });
              }
            },
            errFunc: (err) => {
              this.$notify.error({
                title: "错误",
                message: err,
              });
              console.log(err, "err1");
            },
          };
          request(opt);
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消操作",
          });
        });
    },

    handlePageChange(data) {
      showLoading();
      const opt = {
        url: reqUrl.realNameList,
        method: "POST",
        params: JSON.stringify({
          endTime: this.date[1],
          idCard: this.number,
          nonce: "",
          pageNum: data.pageIndex,
          pageSize: data.pageSize,
          phone: this.phone,
          phoneArea: this.valueArea || this.phoneAreaROLE,
          sign: "",
          startTime: this.date[0],
          token: "",
          type: this.searchType,
        }),
        resFunc: (res) => {
          console.log(res.data);
          if (res.data.code == 0) {
            this.tableData = res.data.data.list;
            this.pageLength = res.data.data.total;
            this.pageSize = res.data.data.pageSize;
            this.currentPage = data.pageIndex;
            hideLoading();
          }

          if (res.data.code != 0) {
            this.$notify.error({
              title: "错误",
              message: res.data.message,
            });
          }
          hideLoading();
        },
        errFunc: (err) => {
          this.$notify.error({
            title: "错误",
            message: err,
          });
          console.log(err, "err1");
        },
      };
      request(opt);
    },

    reset() {
      this.phone = "";
      this.number = "";
      this.date = "";
      this.searchType = "";
      this.valueArea = "";
      this.refresh();
    },

    // 刷新
    refresh() {
      this.reload();
    },
  },
};
</script>

<style lang="scss" scoped>
.status {
  display: flex;

  .el-input {
    width: 300px;
    margin-right: 10px;
  }

  .el-select {
    width: 200px;
    margin-right: 10px;
  }

  .el-date-editor {
    margin-right: 10px;
  }
}

.el-select {
  width: 300px;
}
</style>
